import { Popover } from "@headlessui/react"
import { Link, useLocation, useNavigate } from "react-router-dom"

import { ROUTES } from "../../../constants/routes"

import immunisAI from "../../../assets/images/backgroudImages/immunisAI-navbarContent.svg"
import narrowUp from "../../../assets/images/iconsVector/ic_chevron.svg"
import narrowDown from "../../../assets/images/iconsVector/ic_chevron_down.svg"
import { useState, useEffect } from "react"
import crossIcon from "../../../assets/images/theme/cross.png";
import { MixpanelTracking } from "../../../services/Mixpanel"

const Navbar = () => {
  const [isHidden, setHidden] = useState(false)
  const navigate = useNavigate()
  const [blurBgHeader, setBlurBgHeader] = useState(false);

  useEffect(() => {
    const headerBackground = document.querySelector(".header");
    if(headerBackground){

      if (blurBgHeader) {
        headerBackground.classList.add("header-blurred")
      } else {
        headerBackground.classList.remove("header-blurred")
      }
    }
  }, [blurBgHeader]);

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", function () {
      const ScrollY = window.scrollY;

      if (blurBgHeader === false && ScrollY > 100) {
        setBlurBgHeader(true);
      } else if (blurBgHeader === true && ScrollY < 100) {
        setBlurBgHeader(false);
      }
    });
  }

  return (
    <div
      className={"immunisLogo flex justify-between header"}
    >
      <div className="">
        <img
          className="hover:cursor-pointer headerImage"
          onClick={() => {
            navigate(ROUTES.home)
          }}
          src={immunisAI}
        />
      </div>
      <div>
        <nav className="navbar">
          <div className="block lg:hidden">
            <button className="flex items-center rounded text-teal-200 border-teal-400 hover:text-white hover:border-white p-0 border-0" onClick={() => setHidden(!isHidden)}>
              <svg
                className="fill-white h-6 w-6"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <ul className="navbar-list hidden lg:flex">
            <Nav path={ROUTES.about} text="About" onClick={()=> {MixpanelTracking.getInstance().VisitSection("About","header")}} dropdown>
              <Link
                to={ROUTES.about}
                className="hover:text-[#F2E854] px-[12px] py-[10px]"
              >
                Who we are
              </Link>
              <Link
                to={ROUTES.leadershipTeam}
                className="hover:text-[#F2E854] px-[12px] py-[10px]"
              >
                Leadership team
              </Link>
              <Link
                to={ROUTES.clinicalAdvisory}
                className="hover:text-[#F2E854] px-[12px] py-[10px]"
              >
                Clinical advisory board
              </Link>
              <Link
                to={ROUTES.boardsOfDirectors}
                className="hover:text-[#F2E854] px-[12px] py-[10px]"
              >
                Board of Directors
              </Link>
            </Nav>
            <Nav path={ROUTES.medicalNeed} text="The Medical Need" onClick={()=> {MixpanelTracking.getInstance().VisitSection("The Medical Need","header")}} />
            <Nav path={ROUTES.immunisAISolution} text="Immunis.AI Solution" onClick={()=> {MixpanelTracking.getInstance().VisitSection("Immunis.AI Solution","header")}} />
            <Nav path={ROUTES.liquidBiopsy} text="Liquid Biopsy Pipeline" onClick={()=> {MixpanelTracking.getInstance().VisitSection("Liquid Biopsy Pipeline","header")}} />
            <Nav path={ROUTES.news} text="News" onClick={()=> {MixpanelTracking.getInstance().VisitSection("News","header")}} />
            <Nav path={ROUTES.contactUs} text="Contact" onClick={()=> {MixpanelTracking.getInstance().VisitSection("Contact","header")}} />
          </ul>
          {/* mobile design */}
          {isHidden && (
            <><div className="">
            <img
              className="hover:cursor-pointer headerImage"
              onClick={() => {
                navigate(ROUTES.home)
              }}
              src={immunisAI}
            />
          </div>
            <ul className="navbar-list mobile-nav lg:flex">
            <button className="flex items-center rounded text-teal-200 border-teal-400 hover:text-white hover:border-white p-0 border-0 cross-icon"  onClick={() => setHidden(!isHidden)}>
              <img src={crossIcon} alt="" />
            </button>
              <Nav text="About" dropdown>
                <Link
                  to={ROUTES.about}
                  className="hover:text-[#F2E854] px-[12px] py-[10px]"
                >
                  Who we are
                </Link>
                <Link
                  to={ROUTES.leadershipTeam}
                  className="hover:text-[#F2E854] px-[12px] py-[10px]"
                >
                  Leadership team
                </Link>
                <Link
                  to={ROUTES.clinicalAdvisory}
                  className="hover:text-[#F2E854] px-[12px] py-[10px]"
                >
                  Clinical advisory board
                </Link>
                <Link
                  to={ROUTES.boardsOfDirectors}
                  className="hover:text-[#F2E854] px-[12px] py-[10px]"
                >
                  Board of Directors
                </Link>
              </Nav>
              <Nav path={ROUTES.medicalNeed} text="The Medical Need" />
              <Nav path={ROUTES.immunisAISolution} text="Immunis.AI Solution" />
              <Nav path={ROUTES.liquidBiopsy} text="Liquid Biopsy Pipeline" />
              <Nav path={ROUTES.news} text="News" />
              <Nav path={ROUTES.contactUs} text="Contact" />
            </ul></>
          )}
        </nav>
      </div>
    </div>
  )
}

interface NavProps {
  path?: string
  text: string
  dropdown?: boolean
  children?: React.ReactNode
  onClick?: () => void
}

const Nav = ({
  text,
  path = "#",
  dropdown = false,
  children = "",
  onClick,
}: NavProps) => {
  const location = useLocation();
  const currentPath = location.pathname

  if (dropdown) {
    return (
      <Popover className="relative navbar-item" onClick={onClick}>
        {({ open }) => (
          <>
            <Popover.Button className="flex navbar-link items-center gap-[6px]">
              <span className={`${(open || currentPath.includes(path)) ? "text-[#F2E854]" : "text-white"}`}>
                {text}
              </span>
              <img src={open ? narrowUp : narrowDown} />
            </Popover.Button>
            <Popover.Panel
              className="absolute z-10 min-w-[280px] bg-[#1a1a1a] mt-[4px] p-[6px] text-white flex flex-col rounded-[6px] border !border-[#F2E854]"
              style={{
                boxShadow:
                  "0px 4px 10px 0px rgba(252, 252, 252, 0.16), 0px 0px 2px 0px rgba(252, 252, 252, 0.24)",
              }}
            >
              {children}
            </Popover.Panel>
          </>
        )}
      </Popover>
    )
  }

  return (
    <div className="navbar-item" onClick={onClick}>
      <Link
        to={path}
        className={
          currentPath.includes(path) ? `navbar-link navBarTextYellow` : "navbar-link"
        }
      >
        {text}
      </Link>
      <span
        className={`flex justify-center font-extrabold text-yellow-500 menu-active-dot ${
          currentPath.includes(path) ? "" : "invisible"
        }`}
      >
        <b>.</b>
      </span>
    </div>
  )
}

export default Navbar
