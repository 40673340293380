import { useMemo } from "react";

import emailIcon from "../../assets/images/iconsVector/mail_ic.svg"
import officeIcon from "../../assets/images/iconsVector/location_ic.svg"
import phoneIcon from "../../assets/images/iconsVector/phone_ic.svg"
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

// import Map from "../../assets/images/theme/map.svg"

const index = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA1gjXYobIeMfzpUyfnsJnjdU2XCw5zWO0',
  });
  const center = useMemo(() => ({ lat: 42.501550, lng: -83.117120 }), []);

  return (
    <>
      <div className="lg:grid lg:grid-cols-2 gap-1 mt-5">
        {/* card-1 */}
        <div className="p-sm-4 p-3 m-sm-3 m-3 email-card">
          <div className="pb-3 email-card-body">
            <ul>
              <div className="flex">
                <li>
                  <img src={emailIcon} width={30} height={30} alt="" />
                </li>
                <li>
                  <div className="py-1">
                    <span className="ml-3 text-yellow">Email</span>
                  </div>
                </li>
              </div>
            </ul>
          </div>
          <div className="grid grid-cols-2 mt-3">
            <div className="email-address">
              <ul>
                <li className="mb-2">Info</li>
                <li className="mb-2">Investor Relations</li>
                <li className="mb-2">PR</li>
              </ul>
            </div>
            <div className="email-address">
              <ul>
                <li className="mb-2"><a href="mailto:info@immunis.ai">info@immunis.ai</a></li>
                <li className="mb-2"><a href="mailto:investor.relations@immunis.ai">investor.relations@immunis.ai</a></li>
                <li className="mb-2"><a href="mailto:public.relations@immunis.ai">public.relations@immunis.ai</a></li>
              </ul>
            </div>
          </div>
        </div>
        {/* card-2 */}
        <div className="p-sm-4 p-3 m-sm-3 m-3 email-card">
          <div className="pb-3 email-card-body">
            <ul>
              <div className="flex">
                <li>
                  <img src={phoneIcon} width={30} height={30} alt="" />
                </li>
                <li>
                  <div className="py-1">
                    <span className="ml-3 text-yellow">Phone number</span>
                  </div>
                </li>
              </div>
            </ul>
          </div>
          <div className="grid grid-cols-2 mt-3">
            <div className="email-address">
              <ul>
                <li className="mb-2">Toll free</li>
                <li className="mb-2">Office</li>
                <li className="mb-2">Fax</li>
              </ul>
            </div>
            <div className="email-address">
              <ul>
                <li className="mb-2">888 508 9380</li>
                <li className="mb-2">248 918 0468</li>
                <li className="mb-2">248 809 4805</li>
              </ul>
            </div>
          </div>
        </div>

        {/* card-3 */}
        <div className="p-sm-4 p-3 m-sm-3 m-3 email-card">
          <div className="pb-3 email-card-body">
            <ul>
              <div className="flex">
                <li>
                  <img src={officeIcon} width={30} height={30} alt="" />
                </li>
                <li>
                  <div className="py-1">
                    <span className="ml-3 text-yellow">Office</span>
                  </div>
                </li>
              </div>
            </ul>
          </div>
          <div className="grid grid-cols-2 mt-3">
            <div className="email-address">
              <ul>
                <li className="mb-2">Address</li>
              </ul>
            </div>
            <div className="email-address">
              <ul>
                <li className="mb-2">1310 N Stephenson Hwy Suite 101 Royal Oak, MI 48067</li>
              </ul>
            </div>
          </div>
          <div className="grid grid-cols-2 mt-3">
            <div className="email-address">
              <ul>
                <li className="mb-2">Office Hours</li>
              </ul>
            </div>
            <div className="email-address">
              <ul>
                <li className="mb-2">8:00 – 5:00 ET</li>
              </ul>
            </div>
          </div>
        </div>

        {/* map card */}
        <div className="m-3">
          {/* <img src={Map} alt="" /> */}
          {!isLoaded ? (
            <h1>Loading...</h1>
          ) : (
            <GoogleMap
              mapContainerClassName="map-container"
              center={center}
              zoom={10}
            >
              <Marker position={{ lat: 42.501550, lng: -83.117120 }} />
            </GoogleMap>  
          )}
        </div>
      </div>
    </>
  )
}

export default index
