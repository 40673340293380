import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Field, Form, Formik } from "formik";
import jsonp from "jsonp";
import * as Yup from "yup";

import { ROUTES } from "../../../constants/routes"

import immunisAI from "../../../assets/images/backgroudImages/immunisAI-navbarContent.svg"
import waringError from "../../../assets/images/iconsVector/warningError.svg"
import { MixpanelTracking } from "../../../services/Mixpanel";

const initialValue = {
  FNAME: "",
  LNAME: "",
  EMAIL: "",
}

const ValidationSchema = Yup.object().shape({
  FNAME: Yup.string().required("FirstName is required"),
  LNAME: Yup.string().required("LastName is required"),
  EMAIL: Yup.string().required("Email is required")
});

const index = () => {
  const [ishomePage, setIShomePage] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname;

  useEffect(() => {
    if (path === "/") {
      setIShomePage(true)
    }
  }, []);

  const handleFormSubmit = (values: any, actions: any) => {
    const url = "https://immunis.us21.list-manage.com/subscribe/post-json?u=2b572b065e617a9e9f9b1f944&amp;id=970db65c77&amp;f_id=0025dfe6f0";
    setIsSubmitting(true);

    jsonp(`${url}&EMAIL=${values.EMAIL}&FNAME=${values.FNAME}&LNAME=${values.LNAME}&INQUIRY=${values.INQUIRY}&TITLE=${values.TITLE}&ORG=${values.ORG}&PHONE=${values.PHONE}`, { param: 'c' }, (_:any, data:any) => {
      const { msg, result } = data;

      if(result !== 'error') {
        actions.resetForm({values: ''});
        MixpanelTracking.getInstance().SubscribedToNewsletter(values.EMAIL);
      }

      setIsSubmitting(false);
      alert(msg);
    });
  }

  return (
    <>
      <div className="footerContainer">
        <div className="container ">
          <div className="pt-5 pb-4 py-lg-5">
            <div className="flex align-items-center lg:flex-row flex-col">
              <div className="flex justify-center md:justify-start pb-lg-0 pb-5">
                <span>
                  <img
                    className="hover:cursor-pointer"
                    onClick={() => {
                      navigate(ROUTES.home)
                    }}
                    src={immunisAI}
                  />
                </span>
              </div>
              <div className="flex lg:justify-end w-100 gap-6 lg:flex-row flex-col text-center py-2">
                <Link
                  to="/about"
                  className={path.includes("/about") ? "navBarTextYellow" : "navLink"}
                  onClick={()=>{MixpanelTracking.getInstance().VisitSection("About", "Footer")}}
                >
                  About
                </Link>
                <Link
                  to="/medical-need"
                  className={
                    path.includes("/medical-need") ? "navBarTextYellow" : "navLink"
                  }
                  onClick={()=>{MixpanelTracking.getInstance().VisitSection("The Medical Need", "Footer")}}
                >
                  The Medical Need
                </Link>
                <Link
                  to="/immunis-solution"
                  className={
                    path.includes("/immunis-solution")
                      ? "navBarTextYellow"
                      : "navLink"
                  }
                  onClick={()=>{MixpanelTracking.getInstance().VisitSection("Immunis.AI Solution", "Footer")}}
                >
                  Immunis.AI Solution
                </Link>
                <Link
                  to="/liquidBiopsyPipeline"
                  className={
                    path.includes("/liquidBiopsyPipeline")
                      ? "navBarTextYellow"
                      : "navLink"
                  }
                  onClick={()=>{MixpanelTracking.getInstance().VisitSection("Liquid Biopsy Pipeline", "Footer")}}
                >
                  Liquid Biopsy Pipeline
                </Link>
                <Link
                  to="/news"
                  className={
                    path.includes("/news") || path.includes("/article")
                      ? "navBarTextYellow"
                      : "navLink"
                  }
                  onClick={()=>{MixpanelTracking.getInstance().VisitSection("News", "Footer")}}
                >
                  News
                </Link>
                <Link
                  to={ROUTES.contactUs}
                  className={
                    path.includes(ROUTES.contactUs) ? "navBarTextYellow" : "navLink"
                  }
                  onClick={()=>{MixpanelTracking.getInstance().VisitSection("Contact", "Footer")}}
                >
                  Contact
                </Link>
              </div>
            </div>
          </div>
          <div className="copyRightContent">
            {!ishomePage ? (
              <>
                <div className="joinNewsLetterGrid hidden lg:block">
                  <div className="flex justify-start pb-3">
                    <span className="newsLetterText">Join our newsletter</span>
                  </div>  
                  <Formik
                    initialValues={initialValue}
                    onSubmit={(values, actions) => handleFormSubmit(values, actions)}
                    validationSchema={ValidationSchema}
                  >
                    {({ errors }) => (
                      <Form>
                        <div className="grid grid-cols-7 gap-3">
                          <div className="col-span-2 form-group">
                            <label className="formLabel" htmlFor="FNAME">
                              First name<span className="requiredAstricks">*</span>
                            </label>
                            <Field
                              type="text"
                              id="FNAME"
                              name="FNAME"
                              placeholder="Enter First name"
                              className="text-white form-control formFeild"
                            />
                            {errors.FNAME ? (
                              <div className="flex mb-2 mt-1 formError">
                                <img className="warning-img" src={waringError} />
                                <span className="ml-2">{errors.FNAME}</span>
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-2 form-group">
                            <label className="formLabel" htmlFor="LNAME">
                              Last name <span className="requiredAstricks">*</span>
                            </label>
                            <Field
                              type="text"
                              id="LNAME"
                              name="LNAME"
                              placeholder="Enter Last name"
                              className="text-white form-control formFeild"
                            />
                            {errors.LNAME ? (
                              <div className="flex mb-2 mt-1 formError">
                                <img className="warning-img" src={waringError} />
                                <span className="ml-2">{errors.LNAME}</span>
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-3 form-group">
                            <label className="formLabel" htmlFor="EMAIL">
                              Email address<span className="requiredAstricks">*</span>
                            </label>
                            <div className="grid grid-cols-4 gap-3">
                              <div className="col-span-3">
                                <Field
                                  type="text"
                                  id="EMAIL"
                                  name="EMAIL"
                                  placeholder="Enter email Adress"
                                  className="text-white form-control formFeild"
                                />
                              </div>
                              <div className="col-span-1 mt-2">  
                                <button
                                  disabled={!errors || isSubmitting}
                                  type="submit"
                                  className="contactUsButton"
                                >
                                  <span className="buttonText">Send</span>
                                </button>
                              </div>
                            </div>    
                            {errors.EMAIL ? (
                              <div className="flex mb-2 mt-1 formError">
                                <img className="warning-img" src={waringError} />
                                <span className="ml-2">{errors.EMAIL}</span>
                              </div>
                            ) : null}
                          </div>

                          {/* <div className="submit-button flex flex-column justify-end">
                            <button
                              disabled={!errors || isSubmitting}
                              type="submit"
                              className="mb-3 contactUsButton"
                            >
                              <span className="buttonText">Send</span>
                            </button>
                          </div> */}
                        </div>  
                      </Form>
                    )}
                  </Formik>
                </div>
                <div className="joinNewsLetterGrid-mobile lg:hidden block">
                  <div className="subcribe-news">Want to subscribe to news? <Link to={ROUTES.contactUs}>Click</Link></div>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="md:flex md:justify-between py-5">
              <div className="text-center md:mb-0 mb-3">
                <span className="copyRight">
                  © 2024. ImmunisAI. All rights reserved
                </span>
              </div>
              <div className="newsLetter flex justify-between mt-sm-0 mt-4 md:justify-start">
                <div className="text-center">
                  <Link
                    to="/terms"
                    className={
                      path.includes("/terms") ? "navBarTextYellow mb-3 md:mr-2 md:mb-0 md:mr-4 mr-0" : "navLink mb-3 md:mr-2 md:mb-0 md:mr-4 mr-0"
                    }
                    onClick={()=>{MixpanelTracking.getInstance().TermsOfUse()}}
                  >
                    Terms of Use
                  </Link>
                </div>
                <div className="text-center">
                  <Link
                    to="/privacy-policy"
                    className={
                      path.includes("/privacy-policy") ? "navBarTextYellow" : "navLink"
                    }
                    onClick={()=>{MixpanelTracking.getInstance().PrivacyPolicy()}}
                  >
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
    </>
  )
}

export default index
