// import nationalCancerInstituteImage from '../../assets/images/Images/nationalCancerInstitute.svg'
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import jsonp from "jsonp"

import ContactUsCard from "../../components/cards/contact"
import Footer from "../../components/layout/footer"
import Navbar from "../../components/layout/header"

import waringError from "../../assets/images/iconsVector/warningError.svg"
import { Link } from "react-router-dom"
import { ROUTES } from "../../constants/routes"
import { useState } from "react"
import { MixpanelTracking } from "../../services/Mixpanel"

const initialValue = {
  FNAME: "",
  LNAME: "",
  TITLE: "",
  ORG: "",
  EMAIL: "",
  PHONE: "",
  INQUIRY: "",
  acceptedTerms: false,
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const ValidationSchema = Yup.object().shape({
  FNAME: Yup.string().required("FirstName is required"),
  LNAME: Yup.string().required("LastName is required"),
  EMAIL: Yup.string().required("Email is required"),
  INQUIRY: Yup.string().required("This Feild can not be blank"),
  PHONE: Yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10, "Phone number is not valid")
  .max(10, "Phone number is not valid"),
  acceptedTerms: Yup.boolean().required("Please Agree to Terms and Condition"),
})

const ContactUsPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleFormSubmit = (values: any, actions: any) => {
    if(!values.acceptedTerms) {
      alert("Please Agree to Terms and Condition");
    } else {
      const url = "https://immunis.us21.list-manage.com/subscribe/post-json?u=2b572b065e617a9e9f9b1f944&amp;id=970db65c77&amp;f_id=0025dfe6f0";
      setIsSubmitting(true);

      jsonp(`${url}&EMAIL=${values.EMAIL}&FNAME=${values.FNAME}&LNAME=${values.LNAME}&INQUIRY=${values.INQUIRY}&TITLE=${values.TITLE}&ORG=${values.ORG}&PHONE=${values.PHONE}`, { param: 'c' }, (_:any, data:any) => {
          const { msg, result } = data;

          if(result !== 'error') {
            actions.resetForm({values: ''});
            MixpanelTracking.getInstance().FormSent();
          }

          setIsSubmitting(false);
          alert(msg);
      });
    }
  }

  return (
    <>
      <Navbar></Navbar>
      <div>
        <div className="relative md:grid h-full md:grid-cols-2 gap-0 cancerImageDetails pt-32">
          <div className="form-layer"></div>
          <div>
            <div className="h-full"></div>
          </div>

          <div className="md:ml-10 contact-form px-3 lg:px-0">
            <div className="contactUsHeading">
              <span className="font-Montserrat contact-heading text-transparent bg-clip-text bg-gradient-to-r from-[#378091] to-[#F2E854]">
                CONTACT US
              </span>
            </div>

            <Formik
              initialValues={initialValue}
              onSubmit={(values, actions) => handleFormSubmit(values, actions)}
              validationSchema={ValidationSchema}
            >
              {({ errors }) => (
                <Form>
                  <div className="form-group mb-3">
                    <label className="formLabel" htmlFor="FNAME">
                      First name<span className="requiredAstricks">*</span>
                    </label>
                    <Field
                      type="text"
                      id="FNAME"
                      name="FNAME"
                      placeholder="Enter First name"
                      className="text-white form-control formFeild md:w-75"
                    />
                    {errors.FNAME ? (
                      <div className="flex mb-2 mt-1 formError">
                        <img className="warning-img" src={waringError} />
                        <span className="ml-2">{errors.FNAME}</span>
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group mb-3">
                    <label className="formLabel" htmlFor="LNAME">
                      Last name <span className="requiredAstricks">*</span>
                    </label>
                    <Field
                      type="text"
                      id="LNAME"
                      name="LNAME"
                      placeholder="Enter Last name"
                      className="text-white form-control formFeild md:w-75"
                    />
                    {errors.LNAME ? (
                      <div className="flex mb-2 mt-1 formError">
                        <img className="warning-img" src={waringError} />
                        <span className="ml-2">{errors.LNAME}</span>
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group mb-3">
                    <label className="formLabel" htmlFor="TITLE">
                      Title
                    </label>
                    <Field
                      type="text"
                      id="TITLE"
                      name="TITLE"
                      placeholder="Enter Title"
                      className="text-white form-control formFeild md:w-75"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label className="formLabel" htmlFor="ORG">
                      Organization
                    </label>
                    <Field
                      type="text"
                      id="ORG"
                      name="ORG"
                      placeholder="Enter organization"
                      className="text-white form-control formFeild md:w-75"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label className="formLabel" htmlFor="EMAIL">
                      Email <span className="requiredAstricks">*</span>
                    </label>
                    <Field
                      type="text"
                      id="EMAIL"
                      name="EMAIL"
                      placeholder="Enter email Adress"
                      className="text-white form-control formFeild md:w-75"
                    />
                    {errors.EMAIL ? (
                      <div className="flex mb-2 mt-1 formError">
                        <img className="warning-img" src={waringError} />
                        <span className="ml-2">{errors.EMAIL}</span>
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group mb-3">
                    <label className="formLabel" htmlFor="PHONE">
                      Phone
                    </label>
                    <Field
                      type="text"
                      id="PHONE"
                      name="PHONE"
                      placeholder="Enter Phone"
                      className="text-white form-control formFeild md:w-75"
                    />
                    {errors.PHONE ? (
                      <div className="flex mb-2 mt-1 formError">
                        <img className="warning-img" src={waringError} />
                        <span className="ml-2">{errors.PHONE}</span>
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group mb-3">
                    <label className="formLabel" htmlFor="INQUIRY">
                      Inquiry <span className="requiredAstricks">*</span>
                    </label>
                    <Field
                      type="text"
                      as="textarea"
                      id="INQUIRY"
                      style={{ height: "100px" }}
                      name="INQUIRY"
                      placeholder="Enter Inquiry"
                      className="text-white form-control formFeild md:w-75"
                    />
                    {errors.INQUIRY ? (
                      <div className="flex mb-2 mt-1 formError">
                        <img className="warning-img" src={waringError} />
                        <span className="ml-2">{errors.INQUIRY}</span>
                      </div>
                    ) : null}
                  </div>

                  <div className="flex mt-5">
                    <div className="form-group">
                      <label>
                        <Field
                          type="checkbox"
                          name="acceptedTerms"
                          className={"form-check-input formCheckbox"}
                        />
                      </label>
                    </div>
                    <div>
                      <p className="ml-3 checkboxText">
                        I have read and agree to <Link to={ROUTES.teams} className="link-text" onClick={()=>{MixpanelTracking.getInstance().TermsOfUse}}>&nbsp;Terms of Use</Link>&nbsp;and &nbsp;<Link to={ROUTES.privacy} className="link-text" onClick={()=>{MixpanelTracking.getInstance().PrivacyPolicy}}> Privacy Policy </Link>
                      </p>
                    </div>
                  </div>

                  <button
                    disabled={!errors || isSubmitting}
                    type="submit"
                    className="mb-5 mt-5 contactUsButton md:w-75"
                  >
                    <span className="buttonText">Send</span>
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="flex justify-center mt-4 mb-12">
          <ContactUsCard></ContactUsCard>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}

export default ContactUsPage
