import { HashRouter, Route, Routes } from "react-router-dom"
import "aos/dist/aos.css";

import { ROUTES } from "./constants/routes"

import ArticleComponent11 from "./containers/articles/Participation_In_Active_Surveillance_Patients_International"
import ArticleComponent10 from "./containers/articles/Blood_based_Immune"
import ArticleComponent9 from "./containers/articles/RNASeq_based_liquid"
import ArticleComponent8 from "./containers/articles/Chosen_by_AWS_for_Machine_Learning"
import ArticleComponent7 from "./containers/articles/Achieves_Development_Milestones" 
import ArticleComponent6 from "./containers/articles/Discovery_Cancer_Related_Signals_in_Circulating_Monocytes"
import ArticleComponent5 from "./containers/articles/Announces_Additional_Patents_Granted_By_USPTO"
import ArticleComponent4 from "./containers/articles/Two_Patents_Granted_By_The_European_Patent_Office"
import ArticleComponent3 from "./containers/articles/Patents_Granted_By_USPTO"
import ArticleComponent2 from "./containers/articles/OncoCell_MDx_Appoints_Renowned_Urologic_Pathologist_As_Chief_Medical_Officer"
import ArticleComponent1 from "./containers/articles/Late_Breaking_Poster_Presentation_At_AACR_2019"
import ImmunisLandingPageComponent from "./containers/home"
import PrivacyPolicy from "./containers/privacyPolicy"
import TermsOfUse from "./containers/termOfUse"

import MedicalNeed from "./containers/MedicalNeed"
import About from "./containers/about"
import ContactUsPage from "./containers/contact"
import ImmunisAISolution from "./containers/immunisAISolution"
import LiquidBiopsyPipeline from "./containers/liquidBiopsyPipeline"
import NewsInformation from "./containers/newsInformation"
import ScrollWrapper from "./utils/scroll"

function App() {
  return (
    <HashRouter>
      <ScrollWrapper />
      <Routes>
        <Route path={ROUTES.home} element={<ImmunisLandingPageComponent />} />
        <Route path={ROUTES.news} element={<NewsInformation />} />
        <Route path={ROUTES.contactUs} element={<ContactUsPage />} />
        <Route path={ROUTES.article11} element={<ArticleComponent11 />} />
        <Route path={ROUTES.article10} element={<ArticleComponent10 />} />
        <Route path={ROUTES.article9} element={<ArticleComponent9 />} />
        <Route path={ROUTES.article8} element={<ArticleComponent8 />} />
        <Route path={ROUTES.article7} element={<ArticleComponent7 />} />
        <Route path={ROUTES.article6} element={<ArticleComponent6 />} />
        <Route path={ROUTES.article5} element={<ArticleComponent5 />} />
        <Route path={ROUTES.article4} element={<ArticleComponent4 />} />
        <Route path={ROUTES.article3} element={<ArticleComponent3 />} />
        <Route path={ROUTES.article2} element={<ArticleComponent2 />} />
        <Route path={ROUTES.article1} element={<ArticleComponent1 />} />
        <Route path={ROUTES.teams} element={<TermsOfUse />} />
        <Route path={ROUTES.privacy} element={<PrivacyPolicy />} />
        <Route path={ROUTES.liquidBiopsy} element={<LiquidBiopsyPipeline />} />
        <Route path={ROUTES.about} element={<About />} />
        <Route
          path={ROUTES.immunisAISolution}
          element={<ImmunisAISolution />}
        />
        <Route path={ROUTES.medicalNeed} element={<MedicalNeed />} />
      </Routes>
    </HashRouter>
  )
}
export default App
