export const ROUTES = {
  home: "/",
  news: "/news",
  contactUs: "/contact-us",
  article11: "/articles/Participation_In_Active_Surveillance_Patients_International",
  article10: "/articles/Blood_based_Immune",
  article9: "/articles/RNASeq_based_liquid",
  article8: "/articles/Chosen_by_AWS_for_Machine_Learning",
  article7: "/articles/Achieves_Development_Milestones",
  article6: "/articles/Discovery_Cancer_Related_Signals_in_Circulating_Monocytes",
  article5: "/articles/Announces_Additional_Patents_Granted_By_USPTO",
  article4: "/articles/Two_Patents_Granted_By_The_European_Patent_Office",
  article3: "/articles/Patents_Granted_By_USPTO",
  article2: "/articles/OncoCell_MDx_Appoints_Renowned_Urologic_Pathologist_As_Chief_Medical_Officer",
  article1: "/articles/Late_Breaking_Poster_Presentation_At_AACR_2019",
  teams: "/terms",
  privacy: "/privacy-policy",
  liquidBiopsy: "/liquidBiopsyPipeline",
  immunisAISolution: "/immunis-solution",
  medicalNeed: "/medical-need",
  about: "/about",
  leadershipTeam: "/about/#leadership-team",
  clinicalAdvisory: "/about/#clinical-advisory",
  boardsOfDirectors: "/about/#board-of-directors",
}
